import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql, Link } from "gatsby"

const NotFoundPage = ({ data }) => (
  <Layout title="404">
    <Seo title="404: Not found" />

    <div className="hero-wrap fl-wrap full-height">
      <div
        className="bg"
        data-bg={data.bg404Image.childImageSharp.gatsbyImageData.originalImg}
      />
      <div className="overlay" />
      <div className="error-wrap fl-wrap">
        <div className="container">
          <h2>404</h2>
          <p>
            We're sorry, but the Page you were looking for, couldn't be found.
          </p>
          <div className="clearfix" />
          {/*<form action="#">
            <input
              name="se"
              id="se2"
              type="text"
              className="search"
              placeholder="Search.."
              value="Search..."
            />
            <button className="search-submit color-bg" id="err_submit_btn">
              <i className="fa fa-search" />
            </button>
          </form>*/}
          <div className="clearfix" />
          <p>Or</p>
          <Link to="/" className="btn color-bg">
            Back to Home Page
          </Link>
        </div>
      </div>
      <div className="hero-corner" />
      <div className="hero-corner2" />
      <div className="hero-corner3" />
    </div>
  </Layout>
)

export default NotFoundPage

export const query = graphql`
  {
    bg404Image: file(relativePath: { eq: "bg/long/Apo3D-pylon.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
`
